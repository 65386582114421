$likeColor: #888;

.news-list-item {
    margin-bottom: 7px;

    h2 {
        font-size: 1rem;
        margin: 0;
    }

    .metadata {
        font-size: 0.9rem;
    }

    .likes {
        color: $likeColor;
    }
    a.like {
        color: $likeColor;
        text-decoration: none;
    }
}
